function Software(props) {
    return (
        <section className="columns">
            <h2>Software</h2>

            <p><h3><a href="http://www.boxstreamapp.com">BoxStream</a></h3>
            BoxStream is a cloud music player for Android, currently supporting streaming music from Dropbox.
            Dropbox is a great way to store your music or podcasts and organize them conveniently,
            so that they are accessible on each one of your devices.
            More details on the <a href="http://www.boxstreamapp.com">application website</a>.
            The application is available for download from 
            the <a href="https://play.google.com/store/apps/details?id=com.michalevsky.boxstream">Google Play Store</a>.
            </p>

            <p><h3><a href="http://faust.grame.fr/">Faust</a></h3>
            I used to contribute to the development of the VSTi architecture for the Faust
            musical signal processing language.<br/>
            Faust currently supports VST audio effects and instruments for 32 and 64-bit architectures.
            </p>

		</section>
    )
}

export default Software;